<template>
  <div class="carrier-vehicle">
    <div class="request-tab__item">
      <div class="request-tab__header" @click="changeInfoShowStatus">
        <h2 class="request-tab__title">Сведения о водителе</h2>
        <ChevronUpIcon v-if="infoShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="infoShowStatus === 'HIDDEN'" />
      </div>
      <div class="request-tab__form" v-if="infoShowStatus !== 'HIDDEN'">
        <InputComponent title="Фамилия водителя" v-model.trim="name" @input="change" />
        <InputComponent title="Имя водителя" v-model.trim="surname" @input="change" />
        <InputComponent title="Отчество водителя" v-model.trim="patronymic" @input="change" />
        <InputComponent
          title="Номер телефона"
          v-model.trim="phone"
          mask="# (###) ###-##-##"
          @input="change"
        />
        <SelectComponent
          title="Документ"
          labelName="title"
          v-model="driver_type_id"
          :options="document_types"
          @input="change"
        />
        <InputComponent
          title="Номер документа"
          v-if="driver_type_id && driver_type_id.id === 2"
          v-model.trim="license_number"
          @input="change"
        />
        <BigDateComponent
          title="Дата выдачи"
          v-if="driver_type_id && driver_type_id.id === 2"
          v-model="license_date_of_issue"
          @input="change"
        />
        <InputComponent
          v-if="driver_type_id && driver_type_id.id === 2"
          title="Кем выдан"
          mask="####"
          v-model.trim="license_issued_by"
          @input="change"
        />
        <BigDateComponent
          title="Дата рождения"
          v-if="driver_type_id && driver_type_id.id === 1"
          v-model="passport_date_of_birth"
          @input="change"
        />
        <InputComponent
          v-if="driver_type_id && driver_type_id.id === 1"
          title="Номер паспорта"
          mask="####-######"
          v-model.trim="passport_number"
          @input="change"
        />
        <BigDateComponent
          title="Дата выдачи"
          v-if="driver_type_id && driver_type_id.id === 1"
          v-model="passport_date_of_issue"
          @input="change"
        />
        <InputComponent
          v-if="driver_type_id && driver_type_id.id === 1"
          title="Кем выдан"
          mask="###-###"
          v-model.trim="passport_issued_by"
          @input="change"
        />
      </div>
    </div>
    <div class="request-tab__item">
      <div class="request-tab__header" @click="changeDocsShowStatus">
        <h2 class="request-tab__title">Документы водителя</h2>
        <ChevronUpIcon v-if="docsShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="docsShowStatus === 'HIDDEN'" />
      </div>
      <FileUploadComponent
        v-if="docsShowStatus !== 'HIDDEN'"
        :uploaded="driver_documents"
        @change="filesChange"
      />
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import SelectComponent from "@/components/inputs/SelectComponent";
import InputComponent from "@/components/inputs/InputComponent";
import BigDateComponent from "@/components/inputs/BigDateComponent";
import FileUploadComponent from "@/components/inputs/FileUploadComponent";

export default {
  name: "RequestCreateCarrierDriverStep",
  inject: ["$emitter"],
  props: {
    options: Object,
  },
  data() {
    return {
      infoShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      docsShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      document_types: [
        {
          id: 1,
          title: "Паспорт",
        },
        {
          id: 2,
          title: "Водительское удостоверение",
        },
      ],
      surname: this.options.surname,
      name: this.options.name,
      patronymic: this.options.patronymic,
      phone: this.options.phone,
      driver_type_id: null,
      license_number: this.options.license_number,
      license_date_of_issue: this.options.license_date_of_issue,
      license_issued_by: this.options.license_issued_by,
      driver_documents: this.options.driver_documents,
      passport_date_of_birth: this.options.passport_date_of_birth,
      passport_number: this.options.passport_number,
      passport_date_of_issue: this.options.passport_date_of_issue,
      passport_issued_by: this.options.passport_issued_by,
    };
  },
  created() {
    this.setDriverType();
  },
  methods: {
    setDriverType() {
      this.driver_type_id = this.options.driver_type_id
        ? this.document_types.find((t) => {
            return t.id === this.options.driver_type_id;
          })
        : this.document_types[0];
    },
    filesChange(e) {
      this.driver_documents = e;
      this.change();
    },
    change() {
      this.$emitter.emit("carrier:change", {
        surname: this.surname,
        name: this.name,
        patronymic: this.patronymic,
        phone: this.phone,
        driver_type_id: this.driver_type_id,
        license_number: this.license_number,
        license_date_of_issue: this.license_date_of_issue,
        license_issued_by: this.license_issued_by,
        driver_documents: this.driver_documents,
        passport_date_of_birth: this.passport_date_of_birth,
        passport_number: this.passport_number,
        passport_date_of_issue: this.passport_date_of_issue,
        passport_issued_by: this.passport_issued_by,
      });
    },
    changeInfoShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.infoShowStatus === "MOBILE") {
          this.infoShowStatus = "HIDDEN";
        } else {
          this.infoShowStatus = "MOBILE";
        }
      } else {
        this.infoShowStatus = "DESKTOP";
      }
    },
    changeDocsShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.docsShowStatus === "MOBILE") {
          this.docsShowStatus = "HIDDEN";
        } else {
          this.docsShowStatus = "MOBILE";
        }
      } else {
        this.docsShowStatus = "DESKTOP";
      }
    },
  },
  components: {
    FileUploadComponent,
    BigDateComponent,
    InputComponent,
    SelectComponent,
    ChevronDownIcon,
    ChevronUpIcon,
  },
};
</script>
