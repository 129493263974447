<template>
  <div class="carrier-tab" v-if="!loading">
    <div class="carrier-tab__types" v-if="!isContractor && !isPassportOffice">
      <RadioComponent v-model="delivery_type_id" :value="1" name="delivery_type_id" @change="change">
        <span>Доставка осуществляется своими силами</span>
      </RadioComponent>
      <RadioComponent v-model="delivery_type_id" :value="2" name="delivery_type_id" @change="change">
        <span>Доставка осуществляется силами грузоперевозчика</span>
      </RadioComponent>
      <SelectComponent
        v-if="delivery_type_id === 2"
        title="Выберите грузоперевозчика"
        labelName="title"
        v-model="company_id"
        :options="contractors"
        @input="change"
      />
    </div>
    <template v-if="delivery_type_id === 1 || isContractor || isPassportOffice">
      <ul class="steps" v-if="isMobile">
        <li
          class="step"
          :class="{
            'step--active': currentStep === i,
            'step--done': currentStep > i,
          }"
          v-for="(step, i) in steps"
          :key="i"
        >
          <div class="step-icon">
            <CheckSymbolIcon />
            <Component :is="step.icon" />
          </div>
          <span class="step-name">{{ step.title }}</span>
        </li>
      </ul>
      <Flickity ref="flickity" :options="flickityOptions" class="steps" v-else>
        <div
          class="step"
          :class="{
            'step--active': currentStep === i,
            'step--done': currentStep > i,
          }"
          v-for="(step, i) in steps"
          :key="i"
        >
          <div class="step-icon">
            <CheckSymbolIcon />
            <Component :is="step.icon" />
          </div>
          <span class="step-name">{{ step.title }}</span>
        </div>
      </Flickity>
      <template v-for="(step, i) in steps">
        <Component :is="step.step" v-show="currentStep === i" :options="step.options" ref="step" :key="i" />
      </template>
      <div
        class="request-controls"
        :class="{
          'request-controls--first': currentStep === 0,
          'request-controls--last': currentStep === steps.length - 1,
        }"
      >
        <button
          type="button"
          class="back-btn request-controls--no-mobile"
          @click="currentStep = currentStep - 1"
          v-if="currentStep !== 0"
        >
          <ChevronLeftIcon />
          <span>Назад</span>
        </button>
        <button
          type="button"
          class="btn-gray btn-gray--outline request-controls--mobile btn-gray--icon-left"
          @click="currentStep = currentStep - 1"
          v-if="currentStep !== 0"
        >
          <ChevronLeftIcon />
          <span>Назад</span>
        </button>
        <button
          type="button"
          class="btn btn--outline btn--icon"
          @click="currentStep = currentStep + 1"
          v-if="currentStep !== steps.length - 1"
        >
          <span>Далее</span>
          <ChevronRightIcon />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon";
import RequestCreateCarrierCargoStep from "./components/cargo.vue";
import RequestCreateCarrierDriverStep from "./components/driver.vue";
import RequestCreateCarrierVehicleStep from "./components/vehicle.vue";
import BusIcon from "@/components/svg/BusIcon";
import ArchiveIcon from "@/components/svg/ArchiveIcon";
import UserIcon from "@/components/svg/UserIcon";
import ChevronLeftIcon from "@/components/svg/ChevronLeftIcon";
import ChevronRightIcon from "@/components/svg/ChevronRightIcon";
import Flickity from "vue-flickity";
import CheckComponent from "@/components/inputs/CheckComponent";
import RadioComponent from "@/components/inputs/RadioComponent";
import SelectComponent from "@/components/inputs/SelectComponent";

export default {
  name: "RequestCreateCarrierTab",
  inject: ["$emitter"],
  beforeCreate() {
    this.$store
      .dispatch("GET_USERS", {
        context: this,
      })
      .then(() => {
        this.loading = false;
      });
  },
  props: {
    options: Object,
  },
  data() {
    return {
      loading: true,
      currentStep: 0,
      company_id: this.options.company_id,
      delivery_type_id: this.options.delivery_type_id,
      flickityOptions: {
        freeScroll: true,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
      },
      steps: [
        {
          id: 0,
          title: "Сведения о транспорте",
          icon: BusIcon,
          step: RequestCreateCarrierVehicleStep,
          valid: false,
          options: this.options,
        },
        {
          id: 1,
          title: "Сведения о водителе",
          icon: UserIcon,
          step: RequestCreateCarrierDriverStep,
          valid: false,
          options: this.options,
        },
        {
          id: 2,
          title: "Сведения о грузе",
          icon: ArchiveIcon,
          step: RequestCreateCarrierCargoStep,
          valid: false,
          options: this.options,
        },
      ],
    };
  },
  computed: {
    contractors() {
      return this.$store.state.users
        .filter((u) => {
          return u.role.filter((r) => {
            return r.name === "contractor";
          }).length;
        })
        .map((u) => {
          if (u && u.company) {
            return {
              id: u.company.id,
              title: u.company.company_name,
            };
          }
          return (u.title = null);
        })
        .filter((u) => u && u.title);
    },
    isMobile() {
      return window.innerWidth > 768;
    },
    isPassportOffice() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "passport_office" || r.name === "passport_office_leader";
        }).length;
      }
      return false;
    },
    isContractor() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "contractor";
        }).length;
      }
      return true;
    },
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    change() {
      this.$emitter.emit("carrier:change", {
        delivery_type_id: this.delivery_type_id,
        company_id: this.company_id,
      });
    },
  },
  components: {
    SelectComponent,
    RadioComponent,
    CheckComponent,
    ChevronRightIcon,
    ChevronLeftIcon,
    CheckSymbolIcon,
    Flickity,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/steps"
@import "~@/styles/parts/carrier-tab.styl"
</style>
