<template>
  <div class="page-header">
    <div class="page-header__title">
      <a @click.prevent="$router.go(-1)" href="#" type="button" class="page__back">
        <ArrowLeftIcon />
        <span>Вернуться назад</span>
      </a>
      <h1 class="page__title" v-if="$route.name === 'RequestCreateContinue'">Новая заявка</h1>
      <h1 class="page__title" v-else>Заявка №{{ $route.params.id }}</h1>
    </div>
    <div class="page-header__actions" v-if="request">
      <button
        class="btn btn--icon-left"
        :class="{
          'btn--loading': sendLoading,
        }"
        v-if="request.can_send"
        @click="send"
      >
        <LoaderIcon v-if="sendLoading" class="loading-rotate" />
        <CheckSymbolIcon v-else />
        <span>Отправить</span>
      </button>
      <button
        class="btn btn--icon-left"
        :class="{
          'btn--loading': acceptLoading,
        }"
        type="button"
        @click="acceptOrDeclineRequest()"
        v-if="request.can_accept"
      >
        <LoaderIcon v-if="acceptLoading" class="loading-rotate" />
        <CheckSymbolIcon v-else />
        <span>Принять</span>
      </button>
      <button
        class="btn-red btn--icon-left"
        :class="{
          'btn-red--outline': !declineLoading,
          'btn-red--loading': declineLoading,
        }"
        @click="decline"
        type="button"
        v-if="request.can_accept"
      >
        <LoaderIcon v-if="declineLoading" class="loading-rotate" />
        <CloseIcon v-else />
        <span>Отклонить</span>
      </button>
      <button
        class="btn-blue btn-blue--icon-left"
        :class="{
          'btn-blue--outline': !saveLoading,
          'btn-blue--loading': saveLoading,
        }"
        type="button"
        v-if="request.can_change_curator"
        @click="saveCurator(false)"
      >
        <LoaderIcon v-if="saveLoading" class="loading-rotate" />
        <SaveIcon v-else />
        <span>Сохранить</span>
      </button>
      <button
        class="btn-blue btn-blue--icon-left"
        :class="{
          'btn-blue--outline': !saveLoading,
          'btn-blue--loading': saveLoading,
        }"
        type="button"
        v-else-if="request.can_edit || request.can_edit_delivery"
        @click="save(false)"
      >
        <LoaderIcon v-if="saveLoading" class="loading-rotate" />
        <SaveIcon v-else />
        <span>Сохранить</span>
      </button>
    </div>
  </div>
</template>

<script>
import SaveIcon from "@/components/svg/SaveIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon.vue";
import LoaderIcon from "@/components/svg/LoaderIcon.vue";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon.vue";
import DeclineRequestModal from "./declineModal.vue";
import moment from "moment";
import SAVE_CURATOR from "@/graphql/User/mutations/ChangeCurator.graphql";

export default {
  name: "RequestHeaderComponent",
  props: {
    carrierForm: Object,
    cargoForm: Object,
    request: Object,
  },
  data() {
    return {
      saveSuccessMessage: "Изменения успешно сохранены",
      sendLoading: false,
      saveLoading: false,
      acceptLoading: false,
      declineLoading: false,
    };
  },
  components: {
    ArrowLeftIcon,
    LoaderIcon,
    CheckSymbolIcon,
    CloseIcon,
    SaveIcon,
  },
  computed: {
    isPassportOffice() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "passport_office" || r.name === "passport_office_leader";
        }).length;
      }
      return false;
    },
    isContractor() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return r.name === "contractor";
        }).length;
      }
      return false;
    },
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    getId(item) {
      if (item) {
        return item.id;
      }
      return null;
    },
    formatFiles(files) {
      if (files) {
        return files.map((f) => {
          if (f.join) {
            f.join_id = f.join.id;
            delete f.id;
            delete f.join;
          }
          delete f.__typename;
          return f;
        });
      }
      return null;
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      }
      return null;
    },
    send() {
      if (this.request.can_edit) {
        this.save(true)
          .then(() => {
            this.saveLoading = false;
            this.$notify({
              title: "Сохранено",
              text: this.saveSuccessMessage,
              type: "success",
            });
            this.sendRequest();
          })
          .catch(this.notifyErrors);
      } else {
        this.sendRequest();
      }
    },
    sendRequest() {
      if (!this.sendLoading) {
        this.sendLoading = true;
        this.$store
          .dispatch("SEND_REQUEST", {
            context: this,
            variables: {
              request_id: parseInt(this.$route.params.id),
            },
          })
          .then(() => {
            this.sendLoading = false;
            this.$notify({
              title: "Отправлено",
              text: "Заявка ушла на проверку",
              type: "success",
            });
            this.$router.push({
              name: "Requests",
            });
          })
          .catch(({ graphQLErrors }) => {
            this.$store.dispatch("notifyGQErrors", {
              context: this,
              errors: graphQLErrors,
            });
            this.sendLoading = false;
          });
      }
    },
    async saveCurator() {
      if (!this.saveLoading) {
        this.saveLoading = true;
        this.$apollo
          .mutate({
            mutation: SAVE_CURATOR,
            variables: {
              request_id: parseInt(this.$route.params.id),
              supplier_id: parseInt(this.cargoForm.supplier_id.id),
            },
          })
          .then(({ data }) => {
            if (data && data.ChangeCurator) {
              this.$notify({
                title: "Отправлено",
                text: data.ChangeCurator.message,
                type: "success",
              });
            }
            this.saveLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.$store.dispatch("notifyGQErrors", {
              context: this,
              errors: graphQLErrors,
            });
            this.saveLoading = false;
          });
      }
    },
    async save(fromSend = false) {
      if (!this.saveLoading) {
        this.saveLoading = true;
        let variables = {
          request_id: parseInt(this.$route.params.id),
          delivery_date: this.formatDate(this.cargoForm.delivery_date),
          contracts: this.cargoForm.contracts.map((c) => c.id),
          cargo: this.cargoForm.cargo.map((c) => ({
            cargo_id: c.cargo_id,
            unit: c.unit,
            count: parseInt(c.count || 0),
          })),
          cargo_documents: this.formatFiles(this.cargoForm.files),
          company_id: this.getId(this.carrierForm.company_id),
          delivery_type_id: this.carrierForm.delivery_type_id,
          transport_type_id: this.getId(this.carrierForm.transport_type_id),
          state_number: this.carrierForm.state_number,
          mark_id: this.getId(this.carrierForm.mark_id),
          registration_number: this.carrierForm.registration_number,
          car_documents: this.formatFiles(this.carrierForm.car_documents),
          surname: this.carrierForm.surname,
          name: this.carrierForm.name,
          patronymic: this.carrierForm.patronymic,
          phone: this.carrierForm.phone,
          driver_type_id: this.getId(this.carrierForm.driver_type_id),
          license_number: this.carrierForm.license_number,
          license_date_of_issue: this.formatDate(this.carrierForm.license_date_of_issue),
          license_issued_by: this.carrierForm.license_issued_by,
          driver_documents: this.formatFiles(this.carrierForm.driver_documents),
          passport_date_of_birth: this.formatDate(this.carrierForm.passport_date_of_birth),
          passport_number: this.carrierForm.passport_number,
          passport_date_of_issue: this.formatDate(this.carrierForm.passport_date_of_issue),
          passport_issued_by: this.carrierForm.passport_issued_by,
          warehouse_id: this.getId(this.carrierForm.warehouse_id),
          shipment_id: this.getId(this.carrierForm.shipment_id),
          cargo_weight: this.carrierForm.cargo_weight,
          option_id: this.getId(this.carrierForm.option_id),
          unloading_id: this.getId(this.carrierForm.unloading_id),
          has_other_cargo: this.carrierForm.has_other_cargo,
          other_cargo_documents: this.formatFiles(this.carrierForm.other_cargo_documents),
        };
        // Если нажали отправить, сперва сохраняю
        const promise = this.$store.dispatch(
          this.isContractor || this.isPassportOffice ? "EDIT_DELIVERY" : "SAVE_REQUEST",
          {
            context: this,
            variables: variables,
          }
        );
        if (fromSend) {
          return promise;
        }
        promise
          .then(() => {
            this.saveLoading = false;
            this.$notify({
              title: "Сохранено",
              text: this.saveSuccessMessage,
              type: "success",
            });
          })
          .catch((err) => {
            this.saveLoading = false;
            this.notifyErrors(err);
          });
      }
    },
    notifyErrors({ graphQLErrors }) {
      this.$store.dispatch("notifyGQErrors", {
        context: this,
        errors: graphQLErrors,
      });
    },
    handleAcceptOrDecline(promise) {
      promise.then(({ data }) => {
        this.acceptLoading = false;
        this.declineLoading = false;
        this.$store.dispatch("GET_ME", {
          context: this,
        });
        if (data.AcceptRequest) {
          this.$notify({
            title: "Успешно",
            text: data.AcceptRequest.message,
            type: "success",
          });
        }
        this.$router.push({
          name: "Requests",
        });
      });
    },
    acceptOrDeclineRequest(data) {
      if (this.request.can_edit || this.request.can_edit_delivery) {
        this.save(true)
          .then(() => {
            this.saveLoading = false;
            this.$notify({
              title: "Сохранено",
              text: this.saveSuccessMessage,
              type: "success",
            });
            this.acceptOrDecline(data);
          })
          .catch(this.notifyErrors);
      } else {
        this.acceptOrDecline(data);
      }
    },
    getDispatchData(data) {
      let variables = {
        is_accepted: !data,
        request_id: parseInt(this.$route.params.id),
      };
      if (data && data.message.value) {
        variables.message = data.message.value;
      }
      if (data && data.type.value) {
        variables.return_to = data.type.value.id;
      }
      if (data && data.reason.value) {
        variables.reason_ids = data.reason.value.map((v) => v.id);
      }
      if (!data) {
        this.acceptLoading = true;
      } else {
        this.declineLoading = true;
      }
      let dispatchName = "ACCEPT_OR_DECLINE_REQUEST";
      return {
        dispatchName,
        variables,
      };
    },
    acceptOrDecline(data) {
      if (!this.acceptLoading && !this.declineLoading) {
        let { dispatchName, variables } = this.getDispatchData(data);
        this.handleAcceptOrDecline(
          this.$store.dispatch(dispatchName, {
            context: this,
            variables: variables,
          })
        );
      }
    },
    decline() {
      this.$store.state._modals.push({
        component: DeclineRequestModal,
        options: {
          showTarget: !this.isPassportOffice && !this.isContractor && this.carrierForm.delivery_type_id === 2,
          callback: this.acceptOrDeclineRequest,
        },
      });
    },
  },
};
</script>
