var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"carrier-tab"},[(!_vm.isContractor && !_vm.isPassportOffice)?_c('div',{staticClass:"carrier-tab__types"},[_c('RadioComponent',{attrs:{"value":1,"name":"delivery_type_id"},on:{"change":_vm.change},model:{value:(_vm.delivery_type_id),callback:function ($$v) {_vm.delivery_type_id=$$v},expression:"delivery_type_id"}},[_c('span',[_vm._v("Доставка осуществляется своими силами")])]),_c('RadioComponent',{attrs:{"value":2,"name":"delivery_type_id"},on:{"change":_vm.change},model:{value:(_vm.delivery_type_id),callback:function ($$v) {_vm.delivery_type_id=$$v},expression:"delivery_type_id"}},[_c('span',[_vm._v("Доставка осуществляется силами грузоперевозчика")])]),(_vm.delivery_type_id === 2)?_c('SelectComponent',{attrs:{"title":"Выберите грузоперевозчика","labelName":"title","options":_vm.contractors},on:{"input":_vm.change},model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}}):_vm._e()],1):_vm._e(),(_vm.delivery_type_id === 1 || _vm.isContractor || _vm.isPassportOffice)?[(_vm.isMobile)?_c('ul',{staticClass:"steps"},_vm._l((_vm.steps),function(step,i){return _c('li',{key:i,staticClass:"step",class:{
          'step--active': _vm.currentStep === i,
          'step--done': _vm.currentStep > i,
        }},[_c('div',{staticClass:"step-icon"},[_c('CheckSymbolIcon'),_c(step.icon,{tag:"Component"})],1),_c('span',{staticClass:"step-name"},[_vm._v(_vm._s(step.title))])])}),0):_c('Flickity',{ref:"flickity",staticClass:"steps",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.steps),function(step,i){return _c('div',{key:i,staticClass:"step",class:{
          'step--active': _vm.currentStep === i,
          'step--done': _vm.currentStep > i,
        }},[_c('div',{staticClass:"step-icon"},[_c('CheckSymbolIcon'),_c(step.icon,{tag:"Component"})],1),_c('span',{staticClass:"step-name"},[_vm._v(_vm._s(step.title))])])}),0),_vm._l((_vm.steps),function(step,i){return [_c(step.step,{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep === i),expression:"currentStep === i"}],key:i,ref:"step",refInFor:true,tag:"Component",attrs:{"options":step.options}})]}),_c('div',{staticClass:"request-controls",class:{
        'request-controls--first': _vm.currentStep === 0,
        'request-controls--last': _vm.currentStep === _vm.steps.length - 1,
      }},[(_vm.currentStep !== 0)?_c('button',{staticClass:"back-btn request-controls--no-mobile",attrs:{"type":"button"},on:{"click":function($event){_vm.currentStep = _vm.currentStep - 1}}},[_c('ChevronLeftIcon'),_c('span',[_vm._v("Назад")])],1):_vm._e(),(_vm.currentStep !== 0)?_c('button',{staticClass:"btn-gray btn-gray--outline request-controls--mobile btn-gray--icon-left",attrs:{"type":"button"},on:{"click":function($event){_vm.currentStep = _vm.currentStep - 1}}},[_c('ChevronLeftIcon'),_c('span',[_vm._v("Назад")])],1):_vm._e(),(_vm.currentStep !== _vm.steps.length - 1)?_c('button',{staticClass:"btn btn--outline btn--icon",attrs:{"type":"button"},on:{"click":function($event){_vm.currentStep = _vm.currentStep + 1}}},[_c('span',[_vm._v("Далее")]),_c('ChevronRightIcon')],1):_vm._e()])]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }