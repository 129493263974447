<template>
  <div class="carrier-vehicle">
    <div class="request-tab__item">
      <div class="request-tab__header" @click="changeInfoShowStatus">
        <h2 class="request-tab__title">Сведения о грузе</h2>
        <ChevronUpIcon v-if="infoShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="infoShowStatus === 'HIDDEN'" />
      </div>
      <div class="request-tab__form" v-if="infoShowStatus !== 'HIDDEN'">
        <SelectComponent
          title="Склад отгрузки"
          labelName="title"
          v-model="warehouse_id"
          :options="warehouseList"
          @input="change"
          :loading="loading"
        />
        <SelectComponent
          title="Способ отгрузки"
          labelName="title"
          v-model="shipment_id"
          :options="shipmentList"
          @input="change"
          :loading="loading"
        />
        <SelectComponent
          title="Техника для разгрузки"
          labelName="title"
          v-model="option_id"
          :options="optionsList"
          @input="change"
          :loading="loading"
        />
        <InputComponent
          title="Введите вес груза (кг)"
          type="number"
          v-model.trim="cargo_weight"
          @input="change"
        />
        <SelectComponent
          title="Дополнительные сведения"
          class="request-tab__form-full"
          labelName="title"
          v-model="unloading_id"
          :options="unloadingList"
          @input="change"
          :loading="loading"
        />
      </div>
    </div>
    <div class="request-tab__item">
      <CheckComponent v-model="has_other_cargo" class="request-tab__check" @change="change">
        Есть попутный груз
      </CheckComponent>
    </div>
    <div class="request-tab__item" v-if="has_other_cargo">
      <div class="request-tab__header" @click="changeDocsShowStatus">
        <h2 class="request-tab__title">Документы на попутный груз</h2>
        <ChevronUpIcon v-if="docsShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="docsShowStatus === 'HIDDEN'" />
      </div>
      <FileUploadComponent
        v-if="docsShowStatus !== 'HIDDEN'"
        :uploaded="other_cargo_documents"
        @change="filesChange"
      />
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import SelectComponent from "@/components/inputs/SelectComponent";
import InputComponent from "@/components/inputs/InputComponent";
import CheckComponent from "@/components/inputs/CheckComponent";
import FileUploadComponent from "@/components/inputs/FileUploadComponent";

export default {
  name: "RequestCreateCarrierCargoStep",
  inject: ["$emitter"],
  props: {
    options: Object,
  },
  data() {
    return {
      loading: true,
      infoShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      docsShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      optionsList: [],
      unloadingList: [],
      shipmentList: [],
      warehouseList: [],
      warehouse_id: this.options.warehouse_id,
      shipment_id: this.options.shipment_id,
      cargo_weight: this.options.cargo_weight,
      option_id: this.options.option_id,
      unloading_id: this.options.unloading_id,
      has_other_cargo: this.options.has_other_cargo,
      other_cargo_documents: this.options.other_cargo_documents,
    };
  },
  beforeCreate() {
    this.$store
      .dispatch("GET_CREATE_REQUEST_CARGO_INPUTS", {
        context: this,
      })
      .then(({ data }) => {
        this.warehouseList = data.AllWarehouses;
        this.optionsList = data.AllOptions;
        this.unloadingList = data.AllUnloadings;
        this.shipmentList = data.AllShipments;
        this.loading = false;
      });
  },
  methods: {
    filesChange(e) {
      this.other_cargo_documents = e;
      this.change();
    },
    change() {
      this.$emitter.emit("carrier:change", {
        warehouse_id: this.warehouse_id,
        shipment_id: this.shipment_id,
        cargo_weight: this.cargo_weight,
        option_id: this.option_id,
        unloading_id: this.unloading_id,
        has_other_cargo: this.has_other_cargo,
        other_cargo_documents: this.other_cargo_documents,
      });
    },
    changeInfoShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.infoShowStatus === "MOBILE") {
          this.infoShowStatus = "HIDDEN";
        } else {
          this.infoShowStatus = "MOBILE";
        }
      } else {
        this.infoShowStatus = "DESKTOP";
      }
    },
    changeDocsShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.docsShowStatus === "MOBILE") {
          this.docsShowStatus = "HIDDEN";
        } else {
          this.docsShowStatus = "MOBILE";
        }
      } else {
        this.docsShowStatus = "DESKTOP";
      }
    },
  },
  components: {
    FileUploadComponent,
    CheckComponent,
    InputComponent,
    SelectComponent,
    ChevronDownIcon,
    ChevronUpIcon,
  },
};
</script>
