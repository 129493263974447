var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__title"},[_c('a',{staticClass:"page__back",attrs:{"href":"#","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_c('ArrowLeftIcon'),_c('span',[_vm._v("Вернуться назад")])],1),(_vm.$route.name === 'RequestCreateContinue')?_c('h1',{staticClass:"page__title"},[_vm._v("Новая заявка")]):_c('h1',{staticClass:"page__title"},[_vm._v("Заявка №"+_vm._s(_vm.$route.params.id))])]),(_vm.request)?_c('div',{staticClass:"page-header__actions"},[(_vm.request.can_send)?_c('button',{staticClass:"btn btn--icon-left",class:{
        'btn--loading': _vm.sendLoading,
      },on:{"click":_vm.send}},[(_vm.sendLoading)?_c('LoaderIcon',{staticClass:"loading-rotate"}):_c('CheckSymbolIcon'),_c('span',[_vm._v("Отправить")])],1):_vm._e(),(_vm.request.can_accept)?_c('button',{staticClass:"btn btn--icon-left",class:{
        'btn--loading': _vm.acceptLoading,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.acceptOrDeclineRequest()}}},[(_vm.acceptLoading)?_c('LoaderIcon',{staticClass:"loading-rotate"}):_c('CheckSymbolIcon'),_c('span',[_vm._v("Принять")])],1):_vm._e(),(_vm.request.can_accept)?_c('button',{staticClass:"btn-red btn--icon-left",class:{
        'btn-red--outline': !_vm.declineLoading,
        'btn-red--loading': _vm.declineLoading,
      },attrs:{"type":"button"},on:{"click":_vm.decline}},[(_vm.declineLoading)?_c('LoaderIcon',{staticClass:"loading-rotate"}):_c('CloseIcon'),_c('span',[_vm._v("Отклонить")])],1):_vm._e(),(_vm.request.can_change_curator)?_c('button',{staticClass:"btn-blue btn-blue--icon-left",class:{
        'btn-blue--outline': !_vm.saveLoading,
        'btn-blue--loading': _vm.saveLoading,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.saveCurator(false)}}},[(_vm.saveLoading)?_c('LoaderIcon',{staticClass:"loading-rotate"}):_c('SaveIcon'),_c('span',[_vm._v("Сохранить")])],1):(_vm.request.can_edit || _vm.request.can_edit_delivery)?_c('button',{staticClass:"btn-blue btn-blue--icon-left",class:{
        'btn-blue--outline': !_vm.saveLoading,
        'btn-blue--loading': _vm.saveLoading,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.save(false)}}},[(_vm.saveLoading)?_c('LoaderIcon',{staticClass:"loading-rotate"}):_c('SaveIcon'),_c('span',[_vm._v("Сохранить")])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }