<template>
  <aside class="aside" v-if="request">
    <div class="aside-card">
      <div class="aside-card__header">
        <span class="aside-card__id">Сведения о грузе</span>
        <EyeOnIcon />
      </div>
      <div class="aside-card__body">
        <div class="aside-card__item" v-if="contractsNumbers">
          <span class="aside-card__item-title">Номера документов</span>
          <span class="aside-card__item-value">{{ contractsNumbers }}</span>
        </div>
        <div class="aside-card__item" v-if="contractsSupplier">
          <span class="aside-card__item-title">Ответственный от АО “Аммоний”</span>
          <span class="aside-card__item-value no-wrap">{{ contractsSupplier }}</span>
        </div>
        <div class="aside-card__item" v-if="request.delivery.delivery_date">
          <span class="aside-card__item-title">Дата доставки</span>
          <span class="aside-card__item-value">
            {{ request.delivery.delivery_date | formatDate }}
          </span>
        </div>
      </div>
    </div>
    <div class="aside-card" v-if="showCompany">
      <div class="aside-card__header">
        <span class="aside-card__id">Сведения о грузоперевозчике</span>
        <EditIcon />
      </div>
      <template v-if="car">
        <div class="aside-card__item" v-if="car.mark_id">
          <span class="aside-card__item-title">Марка автомобиля</span>
          <span class="aside-card__item-value">
            {{ car.mark_id }}
          </span>
        </div>
        <div class="aside-card__item" v-if="car.state_number">
          <span class="aside-card__item-title">Гос.номер автомобиля</span>
          <span class="aside-card__item-value">
            {{ car.state_number }}
          </span>
        </div>
      </template>
      <template v-if="driver">
        <div class="aside-card__item" v-if="driver.name">
          <span class="aside-card__item-title">ФИО водителя</span>
          <span class="aside-card__item-value">
            {{ driver | getFIOCut }}
          </span>
        </div>
        <div class="aside-card__item" v-if="driver.driver_type_id">
          <span class="aside-card__item-title">Документ водителя</span>
          <span class="aside-card__item-value">
            <template v-if="driver.driver_type_id === 1">
              {{ driver.passport_number }}
            </template>
            <template v-else>
              {{ driver.license_number }}
            </template>
          </span>
        </div>
      </template>
    </div>
    <button v-else class="btn-gray btn-gray--outline btn-gray--icon" @click="$emit('carrier')">
      <span>Сведения о грузоперевозчике</span>
      <ChevronRightIcon />
    </button>
  </aside>
</template>

<script>
import EditIcon from "@/components/svg/EditIcon";
import ChevronRightIcon from "@/components/svg/ChevronRightIcon";
import EyeOnIcon from "@/components/svg/EyeOnIcon.vue";

export default {
  name: "RequestCreateAside",
  props: {
    request: Object,
  },
  components: { EyeOnIcon, ChevronRightIcon, EditIcon },
  computed: {
    showCompany() {
      if (this.car && (this.car.mark_id || this.car.state_number)) {
        return true;
      }
      if (this.driver && (this.driver.name || this.driver.driver_type_id)) {
        return true;
      }
      return false;
    },
    car() {
      if (this.request && this.request.delivery && this.request.delivery.car) {
        return this.request.delivery.car;
      }
      return null;
    },
    driver() {
      if (this.request && this.request.delivery && this.request.delivery.delivery_driver) {
        return this.request.delivery.delivery_driver;
      }
      return null;
    },
    contractsSupplier() {
      return this.request && this.request.supplier
        ? `${this.request.supplier.surname}
          ${this.request.supplier.name.substr(0, 1)}.
          ${this.request.supplier.patronymic.substr(0, 1)}`
        : null;
    },
    contractsNumbers() {
      return this.request
        ? this.request.contracts
            .map((c) => {
              return c.ParentNumber;
            })
            .join(", ")
        : null;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/aside"
</style>
