<template>
  <main class="page request-page" :class="{ 'request-create-page': !$route.params.id }">
    <RequestHeaderComponent :carrierForm="carrierForm" :cargoForm="cargoForm" :request="OneRequest" />
    <div class="page__container">
      <RequestCreateAside @carrier="activeButton = buttons[1]" :request="OneRequest" />
      <div class="page__content" v-if="!loading">
        <div class="pills" v-if="$mq !== 'md' && $mq !== 'sm' && activeButton">
          <button
            v-for="(b, i) in buttons"
            :key="i"
            class="pill"
            :class="{ 'pill--selected': b.id === activeButton.id }"
            @click="activeButton = b"
            type="button"
          >
            {{ b.title }}
          </button>
        </div>
        <SelectComponent
          v-else
          labelName="title"
          v-model="activeButton"
          :options="buttons"
          @input="activeButton = $event"
          :clearable="false"
          dark
        />
        <Component
          :is="b.tab"
          v-show="activeButton.id === b.id"
          v-for="(b, i) in buttons"
          :options="b.options"
          :key="i"
        />
      </div>
      <div class="page__content" v-else>
        <LoadingIndicator title="Загрузка" />
      </div>
    </div>
  </main>
</template>

<script>
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";
import CheckSymbolIcon from "@/components/svg/CheckSymbolIcon";
import LoaderIcon from "@/components/svg/LoaderIcon.vue";
import SaveIcon from "@/components/svg/SaveIcon.vue";
import RequestCreateAside from "./components/aside.vue";
import SelectComponent from "@/components/inputs/SelectComponent";
import RequestCreateCargoTab from "./components/cargo/index.vue";
import RequestCreateCarrierTab from "./components/carrier/index.vue";
import LoadingIndicator from "@/components/LoadingIndicator";
import mitt from "mitt";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import DeclineRequestModal from "./components/declineModal.vue";
import RequestHeaderComponent from "./components/header.vue";

const $emitter = mitt();

export default {
  name: "RequestCreatePage",
  provide: {
    $emitter: $emitter,
  },
  data() {
    return {
      loading: false,
      OneRequest: null,
      cargoForm: {
        delivery_date: null,
        supplier_id: null,
        contracts: [],
        cargo: [],
        files: [],
      },
      carrierForm: {
        company_id: null,
        delivery_type_id: null,
        transport_type_id: null,
        state_number: null,
        mark_id: null,
        registration_number: null,
        car_documents: [],
        surname: null,
        name: null,
        patronymic: null,
        phone: null,
        driver_type_id: null,
        license_number: null,
        license_date_of_issue: null,
        license_issued_by: null,
        driver_documents: [],
        passport_date_of_birth: null,
        passport_number: null,
        passport_date_of_issue: null,
        passport_issued_by: null,
        warehouse_id: null,
        shipment_id: null,
        cargo_weight: null,
        option_id: null,
        unloading_id: null,
        has_other_cargo: null,
        other_cargo_documents: [],
      },
      buttons: [],
      activeButton: null,
    };
  },
  created() {
    this.load();
    this.setListeners();
  },
  watch: {
    "$route.params.id"() {
      this.load();
    },
  },
  beforeDestroy() {
    this.destroyListeners();
  },
  methods: {
    destroyListeners() {
      $emitter.off("cargo:date:change", () => {});
      $emitter.off("cargo:supplier:change", () => {});
      $emitter.off("cargo:contracts:change", () => {});
      $emitter.off("cargo:cargo:change", () => {});
      $emitter.off("cargo:docs:change", () => {});
      $emitter.off("carrier:change", () => {});
    },
    setListeners() {
      $emitter.on("cargo:date:change", (e) => {
        this.cargoForm.delivery_date = e;
      });
      $emitter.on("cargo:supplier:change", (e) => {
        this.cargoForm.supplier_id = e;
      });
      $emitter.on("cargo:contracts:change", (e) => {
        this.cargoForm.contracts = e;
      });
      $emitter.on("cargo:cargo:change", (e) => {
        this.cargoForm.cargo = e;
      });
      $emitter.on("cargo:docs:change", (e) => {
        this.cargoForm.files = e;
      });
      $emitter.on("carrier:change", this.carrierTabChange);
    },
    setLoadedData(data) {
      this.OneRequest = data.OneRequest;
      if (data.OneRequest) {
        this.cargoForm.contracts = data.OneRequest.contracts;
        this.cargoForm.cargo = data.OneRequest.cargo;
        this.cargoForm.files = data.OneRequest.cargoDocuments;
        this.carrierForm.car_documents = data.OneRequest.carDocuments;
        this.carrierForm.driver_documents = data.OneRequest.driverDocuments;
        this.carrierForm.other_cargo_documents = data.OneRequest.otherCargoDocuments;
        if (data.OneRequest.supplier) {
          this.cargoForm.supplier_id = {
            id: data.OneRequest.supplier.id,
            name: `${data.OneRequest.supplier.surname} ${data.OneRequest.supplier.name} ${data.OneRequest.supplier.patronymic}`,
          };
        }
        const delivery = data.OneRequest.delivery;
        if (delivery) {
          this.carrierForm.has_other_cargo = delivery.has_other_cargo;
          this.carrierForm.cargo_weight = delivery.cargo_weight;
          this.carrierForm.option_id = delivery.option;
          this.carrierForm.unloading_id = delivery.unloading;
          this.carrierForm.shipment_id = delivery.shipment;
          this.carrierForm.warehouse_id = delivery.warehouse;
          this.setDate("cargoForm", "delivery_date", delivery.delivery_date);
          this.setOption("carrierForm", "company_id", delivery.company, "id", "company_name");
          this.setCode("carrierForm", "delivery_type_id", delivery.type);
          const car = data.OneRequest.delivery.car;
          if (car) {
            this.setOption("carrierForm", "mark_id", car.mark, "id", "title");
            this.setOption("carrierForm", "transport_type_id", car.type, "id", "name");
            this.carrierForm.registration_number = car.registration_number;
            this.carrierForm.state_number = car.state_number;
          }
          const driver = data.OneRequest.delivery.delivery_driver;
          if (driver) {
            this.setCode("carrierForm", "driver_type_id", driver.type);
            this.carrierForm.surname = driver.surname;
            this.carrierForm.name = driver.name;
            this.carrierForm.patronymic = driver.patronymic;
            this.carrierForm.phone = driver.phone;
            this.carrierForm.license_number = driver.license_number;
            this.carrierForm.license_issued_by = driver.license_issued_by;
            this.carrierForm.passport_number = driver.passport_number;
            this.carrierForm.passport_issued_by = driver.passport_issued_by;
            this.setDate("carrierForm", "license_date_of_issue", driver.license_date_of_issue);
            this.setDate("carrierForm", "passport_date_of_birth", driver.passport_date_of_birth);
            this.setDate("carrierForm", "passport_date_of_issue", driver.passport_date_of_issue);
          }
        }
      }
      this.loading = false;
      this.setButtons();
    },
    load() {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("GET_REQUEST", {
            context: this,
            variables: {
              id: parseInt(this.$route.params.id),
            },
          })
          .then(({ data }) => {
            this.setLoadedData(data);
          });
      }
    },
    carrierTabChange(e) {
      Object.keys(e).forEach((key) => {
        if (key in this.carrierForm && e[key]) {
          this.carrierForm[key] = e[key];
        }
      });
    },
    setCode(formName, fieldName, value) {
      if (value) {
        this[formName][fieldName] = value.code;
      }
    },
    setOption(formName, fieldName, value, idField, titleField) {
      if (value) {
        this[formName][fieldName] = {
          id: value[idField],
          title: value[titleField],
        };
      }
    },
    setDate(formName, fieldName, value) {
      if (value) {
        this[formName][fieldName] = new Date(value);
      }
    },
    setButtons() {
      this.buttons = [
        {
          id: 0,
          title: "Сведения о грузе",
          tab: RequestCreateCargoTab,
          options: Object.assign(
            {
              can_edit: this.OneRequest.can_edit,
              can_change_curator: this.OneRequest.can_change_curator,
            },
            this.cargoForm
          ),
        },
        {
          id: 1,
          title: "Сведения о грузоперевозчике",
          tab: RequestCreateCarrierTab,
          options: Object.assign(
            {
              can_edit: this.OneRequest.can_edit,
            },
            this.carrierForm
          ),
        },
      ];
      this.activeButton = this.buttons[0];
    },
  },
  components: {
    RequestHeaderComponent,
    DeclineRequestModal,
    CloseIcon,
    SaveIcon,
    CheckSymbolIcon,
    ArrowLeftIcon,
    LoadingIndicator,
    LoaderIcon,
    SelectComponent,
    RequestCreateAside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/request"
.request-create-page {
  &__tip {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: var(--main_dark);
    margin-bottom 16px
  }

  .page__container {
    width 100%
    display grid
    grid-template-columns 1fr
  }
}
</style>
