<template>
  <div class="request-tab__item">
    <div class="request-tab__header" @click="changeShowStatus">
      <h2 class="request-tab__title">Груз</h2>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <Table :columns="columns" :rows="cargo" listClass="cargo-card-list" v-if="showStatus !== 'HIDDEN'">
      <div slot="empty" class="td-container">
        <span class="table-span">
          <template>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column, index }">
        <div class="td-container" v-if="column.field === 'count'">
          <label class="table-count-input">
            <input
              v-model="form[index].count"
              type="number"
              min="0"
              @input="handleCountInput(index)"
              :max="row.cargo.QtyPlan"
            />
            <span>/ {{ row.cargo.QtyPlan }}</span>
          </label>
        </div>
        <div class="td-container" v-else-if="column.field === 'title'">
          <span class="table-span">
            {{ row.cargo.String }}
          </span>
        </div>
        <div class="td-container" v-else-if="column.field === 'unit'">
          <label class="table-count-input">
            <input v-model="form[index].unit" @input="handleUnitInput(index)" />
          </label>
        </div>
        <div class="td-container" v-else>
          <span class="table-span">
            <b>{{ row[column.field] || "" }}</b>
          </span>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row, index }" class="cargo-card">
        <div class="cargo-card__header">
          <span class="cargo-card__item-title">Наименование</span>
          <span class="cargo-card__id">{{ row.cargo.String }}</span>
        </div>
        <div class="cargo-card__body">
          <div class="cargo-card__item">
            <span class="cargo-card__item-title">Ед. измерения</span>
            <label class="table-count-input">
              <input v-model="form[index].unit" @input="handleUnitInput(index)" />
            </label>
          </div>
          <div class="cargo-card__item">
            <span class="cargo-card__item-title">Количество</span>
            <span class="cargo-card__item-value">
              <label class="table-count-input">
                <input
                  v-model="form[index].count"
                  @input="handleCountInput(index)"
                  type="number"
                  min="0"
                  :max="row.cargo.QtyPlan"
                />
                <span>/ {{ row.cargo.QtyPlan }}</span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";

export default {
  name: "RequestCreateCargoTabCargo",
  inject: ["$emitter"],
  props: {
    cargo: Array,
  },
  data() {
    return {
      form: [],
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      columns: [
        {
          label: "Наименование",
          field: "title",
          width: "100%",
          sortable: false,
        },
        {
          label: "Количество",
          field: "count",
          width: "300px",
          sortable: false,
        },
        {
          label: "Ед. измерения",
          field: "unit",
          width: "200px",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.form = this.cargo.map((c) => ({
      cargo_id: c.cargo_id,
      count: c.count,
      unit: c.unit,
    }));
  },
  methods: {
    handleUnitInput(index) {
      this.cargo[index].unit = this.form[index].unit;
      this.$emitter.emit("cargo:cargo:change", this.cargo);
    },
    handleCountInput(index) {
      if (this.form[index].count > parseInt(this.cargo[index].cargo.QtyPlan || 0)) {
        this.form[index].count = parseInt(this.cargo[index].cargo.QtyPlan || 0);
      }
      if (this.form[index].count < 0) {
        this.form[index].count = 0;
      }
      this.cargo[index].count = this.form[index].count;
      this.$emitter.emit("cargo:cargo:change", this.cargo);
    },
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
  components: { ChevronUpIcon, ChevronDownIcon, Table },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/cargo-card.styl"
</style>
