<template>
  <figure class="icon archive-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 8V21H3V8"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M23 3H1V8H23V3Z"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M10 12H14"
        stroke="#373F41"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ArchiveIcon",
};
</script>
