<template>
  <div class="request-tab__item">
    <div class="request-tab__header" @click="changeShowStatus">
      <h2 class="request-tab__title">Договора</h2>
      <ChevronUpIcon v-if="showStatus === 'MOBILE'" />
      <ChevronDownIcon v-if="showStatus === 'HIDDEN'" />
    </div>
    <Table :columns="columns" :rows="rows" listClass="contracts-card-list" v-if="showStatus !== 'HIDDEN'">
      <div slot="empty" class="td-container">
        <span class="table-span">
          <template>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <div class="td-container" v-if="column.field === 'id'">
          <div class="table-span">
            {{ row.ParentNumber }}
          </div>
        </div>
        <div class="td-container" v-else-if="column.field === 'title'">
          <div class="table-span">
            {{ row.ExternalNumber }}
          </div>
        </div>
        <div class="td-container" v-else-if="column.field === 'responsible'">
          <div class="table-span">
            {{ row.Curator }}
          </div>
        </div>
        <div class="td-container" v-else-if="column.field === 'actions'">
          <div class="table-actions">
            <router-link :to="{ name: 'Request', params: { id: row.id } }" class="table-action">
              <EyeOnIcon />
            </router-link>
            <button
              @click="removeContract(row)"
              v-if="rows.length > 1"
              class="table-action table-action--red-outline"
            >
              <TrashCan2Icon />
            </button>
          </div>
        </div>
        <div class="td-container" v-else>
          <span class="table-span">
            {{ row[column.field] || "" }}
          </span>
        </div>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="contracts-card">
        <div class="contracts-card__header">
          <span class="contracts-card__id">#{{ row.ParentNumber }}</span>
          <button @click="removeContract(row)" class="table-action table-action--red-outline">
            <TrashCan2Icon />
          </button>
        </div>
        <div class="contracts-card__body">
          <div class="contracts-card__item">
            <span class="contracts-card__item-title">{{ row.ExternalNumber }}</span>
          </div>
        </div>
        <div class="contracts-card__footer">
          <div class="contracts-card__item">
            <span class="contracts-card__item-title">Ответственный</span>
            <span class="contracts-card__item-value">
              {{ row.Curator }}
            </span>
          </div>
        </div>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table";
import EyeOnIcon from "@/components/svg/EyeOnIcon";
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import TrashCan2Icon from "@/components/svg/TrashCan2Icon";

export default {
  name: "RequestCreateCargoTabContracts",
  inject: ["$emitter"],
  props: {
    contracts: Array,
  },
  data() {
    return {
      showStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      columns: [
        {
          label: "#",
          field: "id",
          sortable: false,
        },
        {
          label: "Название документа",
          field: "title",
          sortable: true,
        },
        {
          label: "Отвественный от АО Аммоний",
          field: "responsible",
          sortable: true,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
    };
  },
  created() {
    this.rows = this.contracts;
  },
  methods: {
    removeContract(item) {
      if (confirm("Вы уверены?")) {
        const index = this.rows.findIndex((c) => c.id === item.id);
        this.rows.splice(index, 1);
        this.$emitter.emit("cargo:contracts:change", this.rows);
      }
    },
    changeShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.showStatus === "MOBILE") {
          this.showStatus = "HIDDEN";
        } else {
          this.showStatus = "MOBILE";
        }
      } else {
        this.showStatus = "DESKTOP";
      }
    },
  },
  components: {
    TrashCan2Icon,
    ChevronDownIcon,
    ChevronUpIcon,
    EyeOnIcon,
    Table,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/card.styl"
@import "~@/styles/parts/request.styl"
.contracts-card-list {
  @extend .request-list
}

.contracts-card {
  @extend .card
  &__footer {
    padding-top: 16px;
    border-top: 1px solid var(--input_focused_border);
    margin-top: 6px;
  }
}
</style>
