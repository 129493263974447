<template>
  <label
    class="radio"
    :class="{
      'radio--disabled': disabled,
      'radio--checked': checked === value,
      'radio--error': error,
    }"
  >
    <span class="radio__state"></span>
    <input type="radio" :disabled="disabled" :name="name" ref="input" :value="value" @change="inputChange" />
    <slot />
  </label>
</template>

<script>
export default {
  name: "RadioComponent",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    name: String,
    checked: [Number, String],
    value: [Number, String],
    error: Boolean,
    disabled: Boolean,
  },
  methods: {
    inputChange($event) {
      this.$emit("change", parseInt($event.target.value));
    },
  },
};
</script>

<style lang="stylus">
.radio {
  display grid
  grid-template-columns 20px auto
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  grid-gap 8px
  user-select none
  font-size: 0.8750em; // 14px
  line-height: 18px;
  letter-spacing: var(--letter_spacing);

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color var(--error_red) !important

    .radio__state {
      border-color var(--error_red) !important
    }
  }

  &--disabled {
    color var(--main_light_gray)

    .radio__state {
      border: 1px solid var(--main_light_gray);
    }

    &.radio--checked {
      .radio__state {
        border: 1px solid var(--main_light_gray);
        &:after {
          background: var(--main_light_gray);
        }
      }
    }
  }

  &--checked:not(.radio--disabled) {
    color var(--main_dark_gray)

    .radio__state {
      border-color var(--main_accent)
      &:after {
        background: var(--main_accent);
      }
    }
  }

  &__state {
    width 20px
    height 20px
    background: var(--main_white);
    border: 1px solid var(--main_light_gray);
    box-sizing: border-box;
    border-radius: var(--radius);
    display flex
    align-items center
    justify-content center
    transition .3s
    position relative
    &:after {
      content: "";
      width 10px
      height 10px
      flex-shrink 0
      border-radius 100%
      background transparent
      transition .3s
    }
  }
}
</style>
