<template>
  <div class="request-tab">
    <div class="request-tab__form">
      <BigDateComponent title="Дата доставки" v-model="delivery_date" @input="dateChange" />
      <SelectComponent
        @input="supplierChange"
        title="Ответственный от АО “Аммоний”"
        v-if="options.can_change_curator && !loading"
        v-model="supplier_id"
        :options="suppliers"
        :clearable="false"
      />
    </div>
    <RequestCreateCargoTabContracts :contracts="contracts" />
    <RequestCreateCargoTabCargo :cargo="cargo" />
    <RequestCreateCargoTabDocs :uploaded="files" :canEdit="options.can_edit" />
  </div>
</template>

<script>
import RequestCreateCargoTabContracts from "./components/contracts.vue";
import RequestCreateCargoTabCargo from "./components/cargo.vue";
import RequestCreateCargoTabDocs from "./components/documents.vue";
import BigDateComponent from "@/components/inputs/BigDateComponent";
import SelectComponent from "@/components/inputs/SelectComponent.vue";
import ALL_USERS from "@/graphql/User/All.graphql";

export default {
  name: "RequestCreateCargoTab",
  inject: ["$emitter"],
  props: {
    options: Object,
  },
  data() {
    return {
      loading: true,
      delivery_date: null,
      supplier_id: null,
      files: [],
      contracts: [],
      cargo: [],
      suppliers: [],
    };
  },
  created() {
    this.files = this.options.files;
    this.contracts = this.options.contracts;
    this.cargo = this.options.cargo;
    this.delivery_date = this.options.delivery_date;
    this.supplier_id = this.options.supplier_id;
    this.$apollo
      .query({
        query: ALL_USERS,
        variables: {
          role_name: "supplier",
        },
      })
      .then(({ data }) => {
        if (data && data.AllUsers) {
          this.suppliers = data.AllUsers.map((u) => ({
            id: u.id,
            name: `${u.surname} ${u.name} ${u.patronymic}`,
          }));
        }
        this.loading = false;
      });
  },
  methods: {
    dateChange() {
      return this.$emitter.emit("cargo:date:change", this.delivery_date);
    },
    supplierChange() {
      return this.$emitter.emit("cargo:supplier:change", this.supplier_id);
    },
  },
  components: {
    SelectComponent,
    RequestCreateCargoTabDocs,
    BigDateComponent,
    RequestCreateCargoTabCargo,
    RequestCreateCargoTabContracts,
  },
};
</script>

<style lang="stylus">
.cargo-date {
  display flex
  +below(1240px) {
    margin-top 24px
    > * {
      width 100%
    }
  }

  .big-date-select {
    max-width 380px
    width 100%
  }
}
</style>
