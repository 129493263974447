<template>
  <div class="carrier-vehicle">
    <div class="request-tab__item">
      <div class="request-tab__header" @click="changeInfoShowStatus">
        <h2 class="request-tab__title">Сведения о транспорте</h2>
        <ChevronUpIcon v-if="infoShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="infoShowStatus === 'HIDDEN'" />
      </div>
      <div class="request-tab__form" v-if="infoShowStatus !== 'HIDDEN'">
        <SelectComponent
          title="Тип транспорта"
          labelName="title"
          v-model="transport_type_id"
          :options="vehicle_types"
          @input="change"
          :loading="loading"
        />
        <InputComponent title="Введите гос. номер автомобиля" v-model.trim="state_number" @input="change" />
        <SelectComponent
          title="Марка автомобиля"
          labelName="title"
          v-model="mark_id"
          :options="vehicle_marks"
          @input="change"
          :loading="loading"
        />
        <InputComponent title="Номер тех. паспорта" v-model.trim="registration_number" @input="change" />
      </div>
    </div>
    <div class="request-tab__item">
      <div class="request-tab__header" @click="changeDocsShowStatus">
        <h2 class="request-tab__title">Документы на транспорт</h2>
        <ChevronUpIcon v-if="docsShowStatus === 'MOBILE'" />
        <ChevronDownIcon v-if="docsShowStatus === 'HIDDEN'" />
      </div>
      <FileUploadComponent
        v-if="docsShowStatus !== 'HIDDEN'"
        :uploaded="car_documents"
        @change="filesChange"
      />
    </div>
  </div>
</template>

<script>
import ChevronUpIcon from "@/components/svg/ChevronUpIcon";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon";
import SelectComponent from "@/components/inputs/SelectComponent";
import InputComponent from "@/components/inputs/InputComponent";
import FileUploadComponent from "@/components/inputs/FileUploadComponent";

export default {
  name: "RequestCreateCarrierVehicleStep",
  inject: ["$emitter"],
  props: {
    options: Object,
  },
  data() {
    return {
      loading: true,
      infoShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      docsShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      vehicle_types: [],
      vehicle_marks: [],
      transport_type_id: this.options.transport_type_id,
      state_number: this.options.state_number,
      mark_id: this.options.mark_id,
      registration_number: this.options.registration_number,
      car_documents: this.options.car_documents,
    };
  },
  beforeCreate() {
    this.$store
      .dispatch("GET_CREATE_REQUEST_VEHICLE_INPUTS", {
        context: this,
        variables: {
          table_name: "cars",
        },
      })
      .then(({ data }) => {
        this.vehicle_types = data.AllTypes.map((t) => ({
          id: t.id,
          title: t.name,
        }));
        this.vehicle_marks = data.AllCarBrands.map((t) => ({
          id: t.id,
          title: t.title,
        }));
        this.loading = false;
      });
  },
  methods: {
    filesChange(e) {
      this.car_documents = e;
      this.change();
    },
    change() {
      this.$emitter.emit("carrier:change", {
        transport_type_id: this.transport_type_id,
        state_number: this.state_number,
        mark_id: this.mark_id,
        registration_number: this.registration_number,
        car_documents: this.car_documents,
      });
    },
    changeInfoShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.infoShowStatus === "MOBILE") {
          this.infoShowStatus = "HIDDEN";
        } else {
          this.infoShowStatus = "MOBILE";
        }
      } else {
        this.infoShowStatus = "DESKTOP";
      }
    },
    changeDocsShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.docsShowStatus === "MOBILE") {
          this.docsShowStatus = "HIDDEN";
        } else {
          this.docsShowStatus = "MOBILE";
        }
      } else {
        this.docsShowStatus = "DESKTOP";
      }
    },
  },
  components: {
    FileUploadComponent,
    InputComponent,
    SelectComponent,
    ChevronDownIcon,
    ChevronUpIcon,
  },
};
</script>
